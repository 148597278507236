<template>
  <div class="pages page13">
    <div class="page_img"><img src="../assets/tmp04.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Des déclinaisons locales</h1>
      <p>
        <strong
          >Le décret permet néanmoins à l'autorité détentrice du pouvoir de
          police de la circulation, de déroger au cadre général</strong
        >
        en autorisant ces engins à circuler sur le trottoir ou, sous certaines
        conditions, sur les routes dont la vitesse maximale autorisée est
        inférieure ou égale à <strong>80 km/h.</strong>
      </p>
      <p>
        Le déploiement des offres en free-floating est de plus en plus encadré
        localement. À Paris, un nombre d'opérateurs restreints est en vue tandis
        qu'à Lyon, Marseille, Bordeaux, Nantes ou Toulouse, une charte de bonne
        conduite dans la ville est appliquée.
      </p>
      <p>
        Désormais, les conducteurs d'EDPM peuvent donc se référer au Code de la
        route. <strong>Et nul n'est censé ignorer la loi !</strong>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page13 .page_img {
  img {
    object-fit:scale-down;
    object-position: 2vw 0vh;
  }
}
</style>