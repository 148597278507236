<template>
  <div class="pages page19">
    <div class="page_img"><img src="../assets/trotinette03.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Législation : où et comment circuler</h1>
      <p>
        Contrairement aux cycles pour lesquels le fait de circuler sur les
        pistes ou bandes cyclables ne peut être imposé que par l’autorité de
        police (article R. 431-9 CR), les conducteurs d’EDP motorisés ont par
        principe l’obligation de circuler sur les pistes et bandes cyclables en
        agglomération, lorsque ces espaces existent.
      </p>
      <p>
        Ils peuvent bénéficier d’aménagements de voirie dédiés : pistes et
        bandes cyclables, contre-sens dans les voies limitées à 30 km/h, sas au
        niveau des feux.
      </p>
      <p>
        En l’absence de ces espaces, les EDP motorisés peuvent circuler sur les
        routes dont la vitesse maximale autorisée est inférieure ou égale à 50
        km/h.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557955970.hd.mp4?s=957dad11b4c3979b2ba4f60c3f254a6f39b0a6eb&profile_id=175"
        thumbnail="thumbnail-legal.jpg"
      ></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page19",

  components: {
    miniPlayer,
  },
};
</script>

<style lang="scss" scoped>
.page19 .page_img {
  img {
    object-fit:scale-down;
    object-position: 0vw 1vh;
  }
}
</style>