<template>
  <div class="pages page18">
    <div class="page_img"><img src="../assets/trotinette05.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Équipements obligatoires du véhicule et des usagers</h1>
      <p>Équipement des usagers</p>
      <p>
        Le conducteur d’un EDP motorisé est soumis à l’obligation de porter
        <strong
          >un équipement de visibilité en tous lieux (y compris en
          agglomération) la nuit ou de jour en cas de visibilité
          insuffisante.</strong
        >
      </p>
      <p>
        Le choix lui est cependant laissé entre le port d’un gilet de haute
        visibilité ou un équipement rétro-réfléchissant répondant à des
        caractéristiques fixées par arrêté ministériel. Par ailleurs, il pourra
        (mais uniquement à titre complémentaire), porter un dispositif
        d’éclairage non éblouissant et non clignotant.
      </p>
    </div>
  </div>
</template>