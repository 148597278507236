<template>
  <div class="pages page2">
    <div class="page_img"><img src="../assets/tmp01.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Les raisons d'un succès</h1>
      <p>
        L'essor de cet engin répond au besoin de pouvoir se déplacer facilement
        en milieu urbain où la circulation est dense et sur des trajets
        intermodaux quotidiens.
      </p>
      <p>
        En se conjuguant à un autre mode de transport, notamment les transports
        en commun, elle constitue <strong>une vraie alternative à l'usage de
        l'automobile en ville.</strong>
      </p>
      <p>
        Sa prise en main est facile, son utilisation relativement confortable et
        son autonomie est suffisante pour des trajets urbains quotidiens... Mais
        l'augmentation de l'usage de la trottinette repose d'abord sur <strong>la mise
        en service de trottinettes en free-floating depuis 2018</strong>, utilisables via
        une application sur smartphone.
      </p>
      <p>
        Avant son interdiction à Paris depuis le 31 août 2023, 3 opérateurs se partageaient un parc de <strong>15 000</strong> trottinettes électriques.
      </p>
    </div>
  </div>
</template>