<template>
  <div class="pages page1">
    <div class="page_img"><img src="../assets/tmp05.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Un mode de déplacement en forte croissance</h1>
      <p>
        EDPM, <strong>Engins de déplacement personnel motorisés.</strong> Ils
        sont encore nouveaux venus dans le code de la route mais déjà bien présents sur
        les voies publiques.
      </p>
      <p>
        Dans toutes les agglomérations et tout particulièrement en
        Ile-de-France, l'usage des engins de déplacement personnel motorisés,
        notamment des trottinettes électriques, a connu une
        <strong>très forte augmentation depuis 2015.</strong>
      </p>
      <p>
        Entre 2017 et 2022, les ventes de trottinettes électriques ont augmenté
        de <strong>+650%</strong> (102 000 à 765 000 entre 2017 et 2022).
      </p>
      <miniPlayer src="https://player.vimeo.com/progressive_redirect/playback/866767001/rendition/1080p/file.mp4?loc=external&signature=bc26202b58464e635556a94d4dfbf61cb31bd90d51fe9d8af6001a9961df3f08" thumbnail="thumbnail-intro.jpg"></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page1",
  
  components: {
    miniPlayer,
  },

};
</script>

<style lang="scss" scoped>
.page1 .page_img {
  img {
    object-fit:scale-down;
    object-position: 8vw 1vh;
  }
}
</style>