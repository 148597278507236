<template>
  <div class="pages page3">
    <div class="page_img"><img src="../assets/tmp02.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Beaucoup d'avantages mais...</h1>
      <p>
        S'ils répondent à des besoins essentiels en déplacement et constituent
        de véritables alternatives dans les zones urbaines, les EDPM génèrent
        de nombreux problèmes et dangers : <strong>stationnement anarchique des
        trottinettes en libre service, usage sans apprentissage, comportements
        et conduites à risque sur la voie publique.</strong>
      </p>
      <p>Ce sont tous ces effets pervers qui ont conduit plusieurs agglomérations et grandes villes, comme Paris, Nice ou Toulouse, à interdire la location de trottinettes électriques en libre service.</p>
      <p>
        Le cadre législatif récent et l'action des pouvoirs publics locaux sont
        là pour encadrer son utilisation mais <strong>les règles sont encore mal connues
        et les comportements à risque perdurent.</strong>
      </p>
    </div>
  </div>
</template>