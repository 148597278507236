<template>
  <div class="pages page16">
    <div class="page_img"><img src="../assets/epdm.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Définition d'un EDPM</h1>
      <p>
        Le décret publié le 25 octobre 2019 a créé une catégorie EDPM dans le
        Code de la route. Désormais, les EDPM bénéficient d'une réglementation
        adaptée et d'une utilisation encadrée.
      </p>
      <p>
        L'article R311-1 du Code de la route a été complété par les définitions
        suivantes :
      </p>
      <p>
        <strong
          >6.15 Engin de déplacement personnel motorisé : véhicule sans place
          assise, conçu et construit pour le déplacement d’une seule personne et
          dépourvu de tout aménagement destiné au transport de marchandises,
          équipé d’un moteur non thermique ou d’une assistance non thermique et
          dont la vitesse maximale par construction est supérieure à 6 km/h et
          ne dépasse pas 25 km/h.</strong
        >
        Il peut comporter des accessoires comme un panier ou une sacoche de
        petite taille. Un gyropode, tel que défini au paragraphe 71 de l’article
        3 du règlement (UE) n° 168/2013 du Parlement européen et du Conseil du
        15 janvier 2013 relatif à la réception et la surveillance du marché des
        véhicules à deux ou trois roues et des quadricycles, peut être équipé
        d’une selle. Les engins exclusivement destinés aux personnes à mobilité
        réduite sont exclus de cette catégorie.
      </p>
      <p>
        Les dimensions maximales des EDP motorisés sont fixées à 1,35 m de
        longueur et 0,90 m de largeur.
      </p>
    </div>
  </div>
</template>