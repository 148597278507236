<template>
  <div class="pages page8">
    <div class="page_img"><img src="../assets/tmp07.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Le gyropode</h1>
      <p>
        Fonctionnant sur le même principe de réaction au mouvement, le gyropode
        est apparu <strong>au début des années 2000.</strong> Il est constitué
        d'une plateforme dotée de <strong>2 roues parallèles</strong> sur
        laquelle l'utilisateur se tient debout. Un manche de maintien et de
        conduite complète le dispositif.
      </p>

      <p>
        Les gyropodes sont parmi les premiers engins électriques à avoir été
        commercialisés. Leur
        <strong
          >prix élevé, leur poids et leur faible autonomie expliquent leur
          moindre utilisation.</strong
        >
      </p>
    </div>
  </div>
</template>