<template>
  <div class="pages page15">
    <div class="page_img"><img src="../assets/tmp06.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Profil des utilisateurs de trottinettes en free-floating</h1>
      <p>
        Le profil des usagers locaux des trottinettes en free-floating est
        proche de celui des vélos en libre accès :
        <strong>une majorité d'hommes jeunes et actifs (66%)</strong>. Parmi les
        visiteurs (pas de proximité géographique avec le lieu de location) qui
        constituent <strong>42% des usagers</strong>, nombreux sont les
        touristes étrangers dont les pays n'appliquent pas forcément la même
        réglementation.*
      </p>

      <p>
        L'absence d'apprentissage des utilisateurs, les conduites à risque et le
        non-respect d'un code de la route encore méconnu pour ces engins
        laissent craindre une recrudescence des accidents impliquant des EDPM.
      </p>
      <p>
        <span
          >* Usages et usagers des trottinettes électriques en free-floating en
          France, juin 2019, « 6t » bureau de recherche avec le soutien de
          l'ADEME</span
        >
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page15 {
  span {
    font-size: 1vw;
  }
}
</style>