<template>
  <div class="pages page17">
    <div class="page_img"><img src="../assets/trotinette05.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Équipements obligatoires du véhicule et des usagers</h1>
      <p><strong>Équipement du véhicule</strong></p>
      <p>
        Les EDP motorisés doivent
        <strong
          >être équipés d’une sonnette, de catadioptres latéraux orange, d’un
          catadioptre blanc à l’avant et rouge à l’arrière, de feux de position
          avant blanc et arrière rouge.</strong
        >
      </p>
      <p>
        Ils ne sont pas soumis à l’obligation d’être pourvus d’un double
        freinage,
        <strong
          >mais uniquement au fait d’être « munis d’un dispositif de freinage
          efficace »</strong
        > dont les caractéristiques feront l’objet d’un arrêté ministériel. Les
        roues des EDP peuvent ne pas être équipées de pneumatiques.
      </p>
    </div>
  </div>
</template>