<template>
  <div class="pages page12">
    <div class="page_img"><img src="../assets/tmp04.jpg" alt="" /></div>
    <div class="page_content">
      <p>
        Ce texte caractérise ces engins comme
        <strong
          >une nouvelle catégorie de véhicule, avec un statut proche de celui
          des cycles.</strong
        >
      </p>
      <p>Il définit :</p>
      <ul>
        <li>
          <strong>leurs caractéristiques techniques</strong> et
          <strong>les équipements obligatoires</strong> que doivent porter leurs
          conducteurs,
        </li>
        <li>
          <strong>les espaces de circulation</strong> où ces conducteurs doivent
          et peuvent circuler en agglomération et hors agglomération.
        </li>
      </ul>
      <p>
        Parmi les points essentiels de la législation figurent notamment la
        limitation de
        <strong
          >la vitesse des véhicules à 25 km/h, l'interdiction de rouler sur les
          trottoirs</strong
        >
        et
        <strong>l'obligation de circuler sur les pistes cyclables</strong>
        lorsqu'elles existent.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page12 {
  .page_content {
    padding-top: 5vh;
  }

  .page_img {
    img {
      object-fit: scale-down;
      object-position: 2vw 0vh;
    }
  }
}
</style>