<template>
  <div class="pages page28">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/bus_Trotinette.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Attention piétons !</h1>
      <p>
        Un EDPM roule sur la chaussée, son conducteur consulte son portable, des
        piétons traversent en dehors des passages piétons.
      </p>
      <p>
        Un EDPM ne respecte pas le feu rouge et manque de renverser un piéton.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557955691.hd.mp4?s=39ebf82e1aea69187d6fbda7b0233c1ea58b3269&profile_id=175"
        thumbnail="thumbnail-risque1.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <p>
          Sur piste ou bande cyclable, l'usager d'EDPM est soumis au même
          respect de la signalétique que le cycliste et doit donc s'arrêter au
          feu rouge.
        </p>
        <p>
          L'usage d'un téléphone et de tout système d'écoute de types
          oreillettes ou casque audio sur ces engins comme sur tout véhicule est
          interdit.
        </p>
        <p>
          Le freinage des EDPM, s'il se doit d'être efficace, n'est pas aussi
          performant que celui d'un vélo.
        </p>
        <p>
          Une vigilance constante est à maintenir pour anticiper tout mouvement
          et changement concernant la circulation piétonne comme automobile.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page28",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page28 {
  video {
    bottom: 12vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>