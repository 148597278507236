<template>
  <div class="pages page14">
    <div class="page_img"><img src="../assets/accident.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Des accidents de plus en plus graves et fréquents</h1>
      <p>
        En 2017, la Sécurité routière faisait état de 284 blessés et 5 tués chez les usagers d'EDPM. En 2022, le nombre de conducteurs d'EDPM gravement blessés était de 600 et de 35 tués.</p>
       <p>
        Face à la multiplication des accidents, la législation a évolué.
      </p>
      <p>Depuis le 1er septembre 2023, il faut désormais avoir 14 ans pour avoir le droit de conduire un EDPM au lieu de 12 ans jusqu'alors. Les sanctions applicables ont également évoluées.</p>
    </div>
  </div>
</template>