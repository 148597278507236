<template>
  <div class="pages page20">
    <div class="page_img"><img src="../assets/trotinette07.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Législation : où et comment circuler</h1>
      <p>
        <strong
          >Hors agglomération, la circulation des EDP motorisés est donc
          interdite</strong
        >, sauf sur les voies vertes et les pistes cyclables.
      </p>
      <p>
        Les règles de dépassement de ces engins sont également les mêmes que
        pour les cycles : distance de sécurité, possibilité de chevauchement de
        la ligne blanche continue.
      </p>
      <p>
        <strong
          >La circulation sur les trottoirs des EDP motorisés est interdite par
          principe.</strong
        >
        Inversement, leur arrêt ou stationnement est possible sur les trottoirs.
      </p>
    </div>
  </div>
</template>