<template>
  <div class="pages page30">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/portiere_Trotinette.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Attention ouverture de portière !</h1>
      <p>
        Un usager d' EDPM circule sur la chaussée le long de véhicules en
        stationnement.
      </p>
      <p>
        Un automobiliste sort de son véhicule sans effectuer au préalable un
        contrôle visuel.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557956402.hd.mp4?s=7169cb640f02f0c42974089a8d10f8ae567507b0&profile_id=175"
        thumbnail="thumbnail-risque3.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <p>
          Circuler à une distance d'<strong>1 mètre</strong> des véhicules en
          stationnement.
        </p>
        <p>
          Être vigilant à la présence éventuelle d'un conducteur susceptible de
          sortir de son véhicule.
        </p>
        <p>Disposer d'un équipement assurant sa bonne visibilité.</p>
      </div>
    </div>
  </div>
</template>


<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page30",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page30 {
  video {
    bottom: 12vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>