<template>
  <div class="pages page24">
            <div class="page_img"><img src="../assets/tmp06.jpg" alt="" /></div>
    <div class="page_content">
    <h2>En matière de circulation et stationnement</h2>
    <ul>
      <li>Les infractions relatives aux charges, au remorquage, au transport de passager, à la circulation de front sont réprimées par des contraventions de 4ème classe.</li>
      <li>Le majeur accompagnant un enfant de moins de 12 ans circulant sur la chaussée avec un EDPM encourt une contravention de 4ème classe.</li>
      <li>Le fait de circuler sur la chaussée en dehors des pistes ou bandes cyclables, ou hors agglomération, ou en violation des restrictions imposées par l’autorité de police, est puni par une contravention de 4ème classe.</li>
      <li>Le conducteur qui aurait modifié son EDPM pour dépasser la vitesse de 25km/h, encourt une contravention de 4ème classe, assortie de l’immobilisation et de la mise en fourrière de l’engin.</li>
      <li>Le fait de circuler sur la voie publique avec un EDPM dont la vitesse maximale par construction est strictement supérieure à 25 km/h sera puni d'une contravention de 5ème classe, avec confiscation, immobilisation ou mise en fourrière.</li>
    </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .page24 {

    .page_content {
      padding-top: 3vh;
    }
  }
</style>