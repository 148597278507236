<template>
  <div id="header">
    <div id="title">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <line x1="30%" y1="0" x2="100%" y2="20%" stroke="#F1E966" />
        <line x1="0" y1="2%" x2="18%" y2="100%" stroke="#7DC07D" />
        <line x1="40%" y1="100%" x2="100%" y2="70%" stroke="#ED6C9B" />
      </svg>
      <h1>Prévention du risque routier pour les EDPM</h1>
    </div>
    <div id="links">
      <ul>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 1,
          }"
          @click="goto(chapters[0].firstPage)"
        >
          États des lieux
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 2,
          }"
          @click="goto(chapters[1].firstPage)"
        >
          Législation
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 3,
          }"
          @click="goto(chapters[2].firstPage)"
        >
          Conduites à risques
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 4,
          }"
          @click="goto(chapters[3].firstPage)"
        >
          Équipement
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",

  methods: {
    goto: function (page) {
      this.$emit("close-side");
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss">
#header {
  background-color: #3f3853;
  width: 100%;
  height: 25vh;
  position: absolute;
}

//Test media query
@media (aspect-ratio: 4/3) {
  #header {
    background-color: red;
  }
}

#title {
  color: #5d9ad3;
  height: 62%;
  width: 100%;

  h1 {
    font-size: 2.5vw;
    position: absolute;
    top: 2.8vh;
    left: 24vw;
  }

  svg {
    height: 120%;
    width: 100%;
    position: relative;
    z-index: 1;

    line {
      stroke-dasharray: 3%, 2%;
      stroke-width: 0.8vw;
    }
  }
}

#links {
  background-color: #5d9ad3;
  //height: 8%;
  width: 100%;
  position: absolute;
  //top: 16%;
  bottom: 0;
  z-index: 3;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    color: #3f3853;
    display: inline-block;
    font-size: 2.5vw;
    font-weight: 700;
    margin: 1.6vh 3.2vw;
    background-color: #5d9ad3;
    cursor: pointer;

    &.active {
      color: white;
    }
  }
}
</style>