<template>
  <div id="page-content">
    <page1 v-if="$route.params.id_page == 1"></page1>
    <page2 v-if="$route.params.id_page == 2"></page2>
    <page3 v-if="$route.params.id_page == 3"></page3>
    <page4 v-if="$route.params.id_page == 4"></page4>
    <page5 v-if="$route.params.id_page == 5"></page5>
    <page6 v-if="$route.params.id_page == 6"></page6>
    <page7 v-if="$route.params.id_page == 7"></page7>
    <page8 v-if="$route.params.id_page == 8"></page8>
    <page9 v-if="$route.params.id_page == 9"></page9>
    <page10 v-if="$route.params.id_page == 10"></page10>
    <page11 v-if="$route.params.id_page == 11"></page11>
    <page12 v-if="$route.params.id_page == 12"></page12>
    <page13 v-if="$route.params.id_page == 13"></page13>
    <page14 v-if="$route.params.id_page == 14"></page14>
    <page15 v-if="$route.params.id_page == 15"></page15>
    <page16 v-if="$route.params.id_page == 16"></page16>
    <page17 v-if="$route.params.id_page == 17"></page17>
    <page18 v-if="$route.params.id_page == 18"></page18>
    <page19 v-if="$route.params.id_page == 19"></page19>
    <page20 v-if="$route.params.id_page == 20"></page20>
    <page21 v-if="$route.params.id_page == 21"></page21>
    <page22 v-if="$route.params.id_page == 22"></page22>
    <page23 v-if="$route.params.id_page == 23"></page23>
    <page24 v-if="$route.params.id_page == 24"></page24>
    <page25 v-if="$route.params.id_page == 25"></page25>
    <page26 v-if="$route.params.id_page == 26"></page26>
    <page27 v-if="$route.params.id_page == 27"></page27>
    <page28 v-if="$route.params.id_page == 28"></page28>
    <page29 v-if="$route.params.id_page == 29"></page29>
    <page30 v-if="$route.params.id_page == 30"></page30>
    <page31 v-if="$route.params.id_page == 31"></page31>
    <page32 v-if="$route.params.id_page == 32"></page32>
    <page33 v-if="$route.params.id_page == 33"></page33>
    <page34 v-if="$route.params.id_page == 34"></page34>
    <page35 v-if="$route.params.id_page == 35"></page35>
    <page36 v-if="$route.params.id_page == 36"></page36>
  </div>
</template>

<script>
import page1 from "@/pages/page1.vue";
import page2 from "@/pages/page2.vue";
import page3 from "@/pages/page3.vue";
import page4 from "@/pages/page4.vue";
import page5 from "@/pages/page5.vue";
import page6 from "@/pages/page6.vue";
import page7 from "@/pages/page7.vue";
import page8 from "@/pages/page8.vue";
import page9 from "@/pages/page9.vue";
import page10 from "@/pages/page10.vue";
import page11 from "@/pages/page11.vue";
import page12 from "@/pages/page12.vue";
import page13 from "@/pages/page13.vue";
import page14 from "@/pages/page14.vue";
import page15 from "@/pages/page15.vue";
import page16 from "@/pages/page16.vue";
import page17 from "@/pages/page17.vue";
import page18 from "@/pages/page18.vue";
import page19 from "@/pages/page19.vue";
import page20 from "@/pages/page20.vue";
import page21 from "@/pages/page21.vue";
import page22 from "@/pages/page22.vue";
import page23 from "@/pages/page23.vue";
import page24 from "@/pages/page24.vue";
import page25 from "@/pages/page25.vue";
import page26 from "@/pages/page26.vue";
import page27 from "@/pages/page27.vue";
import page28 from "@/pages/page28.vue";
import page29 from "@/pages/page29.vue";
import page30 from "@/pages/page30.vue";
import page31 from "@/pages/page31.vue";
import page32 from "@/pages/page32.vue";
import page33 from "@/pages/page33.vue";
import page34 from "@/pages/page34.vue";
import page35 from "@/pages/page35.vue";
import page36 from "@/pages/page36.vue";

export default {
  name: "Content",

  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    page16,
    page17,
    page18,
    page19,
    page20,
    page21,
    page22,
    page23,
    page24,
    page25,
    page26,
    page27,
    page28,
    page29,
    page30,
    page31,
    page32,
    page33,
    page34,
    page35,
    page36,
  },
};
</script>

<style lang="scss">
#page-content {
  min-height: 70vh;
}

.pages {
  position: relative;
  background-color: #dad8ca;
  height: 71vh;
  width: 100vw;

  .page_img {
    height: 71vh;
    position: absolute;
    left: -8vw;

    clip-path: polygon(0% 0%, 94% 0%, 100% 100%, 0% 100%);

    img {
      height: 71vh;
    }
  }

  .page_content {
    margin-left: 36vw;
    margin-right: 3vw;
    text-align: left;
    margin-top: 2vh;

    h1 {
      padding-top: 4vh;
      font-size: 2vw;
    }

    h2 {
      font-size: 1.6vw;
    }

    p {
      font-size: 1.4vw;
      font-family: MyriadPro;
    }

    button {
      background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgOTAuNTEgNzMuOTkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDkwLjUxIDczLjk5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzNGMzg1Mzt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xOC41OSwzMy45M2g1NC43di02LjM1aC01NC43VjMzLjkzeiBNMTguNTIsNDMuNDZoMjkuMzV2LTYuMzVIMTguNTJWNDMuNDZ6IE05MC41MSw1Mi40NlY4LjQ5aDBWMEgwdjguNDloMAoJdjM2LjYzbDAsMGwwLDAuMDF2MS40N2gwLjIybDMuODQsMjUuM2w0LjkyLDIuMWwxOS4yOS0xMy4wNWg2Mi4yNUw5MC41MSw1Mi40Nkw5MC41MSw1Mi40NnogTTgxLjg5LDUyLjQ2SDI2LjI4djAuMDhMMTAuODIsNjMuMjkKCWwtMi4zNi0xNi43aDAuMTdWOC40OWg3My4yNlY1Mi40NnogTTczLjU3LDE3LjQ5SDE3Ljk4djYuMzVoNTUuNTlWMTcuNDl6Ii8+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      width: 8vw;
      height: 6vw;
      border: 0;
      position: absolute;
      bottom: 11vw;
      right: 8vw;
      cursor: pointer;
    }

    ul {
      list-style-type: none;
      padding-inline-start: 3vw;
    }

    li {
      font-size: 1.5vw;
      font-family: MyriadPro;
      text-indent: -3.2vw;

      &::before {
        background-image: url("../assets/puce-30x30.png");
        background-size: 1.8vw;
        background-repeat: no-repeat;
        background-position: center center;
        content: "";
        display: inline-block;
        width: 2.8vw;
        height: 2.8vw;
        margin-right: .4vw;
        vertical-align: middle;
      }
    }
  }

  .pageConseil {
    background-color: #7dc07d;
    opacity: 0.95;
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    margin-right: 3vw;
    text-align: left;

    .conseilContent {
      padding: 0 3vw;

      h1 {
        margin-top: 2vh;
        padding-top: 0;
        font-size: 2vw;
      }

      p {
        font-size: 1.4vw;
        font-family: MyriadPro;
      }
    }
  }

  .conseilActive {
    opacity: 0.1;
  }

  video {
    width: 25vw;
    position: absolute;
    right: 5vw;
  }
}
</style>

